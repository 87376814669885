import { Router } from "@angular/router";

export class UrlList {
    private static goMerge_RecipeItem(router: Router, recipeId: number, customer_id: number, sourceSystem_id: number, product_id, lobId: number, datesetId: number) {
        const queryParams: IRouteParamRecipeItem = {
            customerId: customer_id?.toString(),
            sourceSystemId: sourceSystem_id?.toString(),
            productId: product_id?.toString(),
            lobId: lobId?.toString(),
            datasetId: datesetId?.toString(),
            recipeId: recipeId?.toString()
        }
        router.navigate(
            ['recipe/recipe-item'],
            {
                queryParams
            }
        );
    }

    public static goToRunRecipe(router: Router, recipeId: number, customer_id: number, sourceSystem_id: number, product_id, lobId: number, datesetId: number) {
        const queryParams: IRouteParamRecipeItem = {
            customerId: customer_id?.toString(),
            sourceSystemId: sourceSystem_id?.toString(),
            productId: product_id?.toString(),
            lobId: lobId?.toString(),
            datasetId: datesetId?.toString(),
            recipeId: recipeId?.toString()
        }
        router.navigate(
            ['recipe/recipe-run'],
            {
                queryParams
            }
        );
    }

    static goMerge_RecipeItemEdit(router: Router, recipeId: number, customer_id: number, sourceSystemId: number, productId: number, lobId: number, datesetId: number) {
        UrlList.goMerge_RecipeItem(router, recipeId, customer_id, sourceSystemId, productId, lobId, datesetId);
    }
    static goMerge_RecipeItemCreate(router: Router, customer_id: number, sourceSystemId: number, productId: number, lobId: number, datesetId: number) {
        UrlList.goMerge_RecipeItem(router, null, customer_id, sourceSystemId, productId, lobId, datesetId);
    }

    static goCustomer_CustomerEdit(router: Router, id: number) {
        const queryParams = {
            customerId: id?.toString()
        }
        router.navigate(
            ['customer/customer-item'],
            { 
                queryParams
            }
        );
    }

    static goCustomer_CustomerCreate(router: Router) {
        router.navigate(
            ['customer/customer-item'],
        );
    }

    static goCustomer_CustomerList(router: Router) {
        router.navigate(
            ['customer/customer-list'],
        );
    }

    static goCustomerDataset_DatasetEdit(router: Router, id: number) {
        const queryParams = {
            datasetId: id?.toString()
        }
        router.navigate(
            ['dataset/customer-dataset/dataset-item'],
            { 
                queryParams
            }
        );
    }

    static goCustomerDataset_DatasetList(router: Router) {
        router.navigate(
            ['dataset/customer-dataset'],
        );
    }

    static goTargetDataset_DatasetCreate(router: Router) {
        router.navigate(
            ['dataset/target-dataset/dataset-item'],
        );
    }

    static goTargetDataset_DatasetEdit(router: Router, id: number) {
        const queryParams = {
            datasetId: id?.toString()
        }
        router.navigate(
            ['dataset/target-dataset/dataset-item'],
            { 
                queryParams
            }
        );
    }

    static goTargetDataset_DatasetList(router: Router) {
        router.navigate(
            ['dataset/target-dataset'],
        );
    }
     
}

export interface IRouteParamRecipeItem {
    customerId: string;
    sourceSystemId: string;
    productId: string;
    lobId: string;
    datasetId: string;
    recipeId?: string;
    scopeId?: string;
}